<script setup lang="ts">
import { storeToRefs } from "pinia"

defineProps({
  modelValue: {
    type: Boolean,
    default: false,
  },
})

const products = useProductVariantsStore()
const { activeProduct: product, activeVariant } =
  storeToRefs(products)

const emit = defineEmits(["update:modelValue", "purchase"])

const handleClose = () => {
  emit("update:modelValue", false)
}
</script>

<template>
  <UModal
    :model-value="modelValue"
    :header-class="'!p-0 !pt-4 !md:pt-8 !pr-4 !md:pr-8'"
    :close-on-click-outside="false"
    :custom-class="'!max-w-4xl'"
    :custom-body-class="'px-7 md:px-11 mb-0 md:pb-11'"
    :custom-inner-class="'!bg-platform-paper'"
    @update:model-value="emit('update:modelValue', $event)"
  >
    <div
      v-if="product && activeVariant"
      class="md:space-y-10 space-y-6 flex flex-col items-center"
    >
      <UPaywallHeader @close="handleClose" />

      <UPaywallStats />

      <UPaywallPricing />

      <UButtonNew
        variant="filled"
        theme="cta"
        pill
        size="md"
        data-pendo="paywall-kjop-tilgang"
        bold
        :to="{
          path: `/produkter/${product?.urlFormatProductTitle}/betaling`,
          query: {
            variant: activeVariant.variantKey,
          },
        }"
      >
        Kjøp tilgang
      </UButtonNew>
    </div>
  </UModal>
</template>
