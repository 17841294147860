<script setup lang="ts">
import { storeToRefs } from "pinia"
import { EcommerceProductPrice } from "~/types/ecommerce/product"
import { useProductPriceDisplay } from "~/composables/useProductPriceDisplay"

const { productPriceFilteredByEAN } = useEcommerceCatalogue()
const { activeVariant, activeProduct: product } = storeToRefs(
  useProductVariantsStore(),
)

const productVariantPrice = computed<
  EcommerceProductPrice | undefined
>(() => productPriceFilteredByEAN(activeVariant.value?.ean))

const { hasDiscountPrice } = useProductPriceDisplay(
  productVariantPrice,
  computed(() => product.value?.productGroupStatusDetails),
)
</script>

<template>
  <div class="flex flex-col items-center gap-4">
    <UPrice
      v-if="
        productVariantPrice?.original &&
        productVariantPrice.original > 0
      "
      :price="productVariantPrice"
      view-type="list"
    />
    <p
      v-if="hasDiscountPrice"
      class="font-normal text-light-blue-darker md:text-product-desktop-price-sm text-product-mobile-price-sm font-national2condensed text-center"
    >
      Før: {{ productVariantPrice?.original }},-
    </p>
  </div>
</template>
