<script setup lang="ts">
import { useProductContentStore } from "~/store/productContentStore"
import { useMediaCounts } from "~/composables/useMediaCounts"
import { unref } from "vue"
import { storeToRefs } from "pinia"
import { IconName } from "~/models/Content/ContentProductPageMediaTypes"

interface MediaTypes {
  icon: IconName
  count: number
  text: string
}

const { subjects } = storeToRefs(useProductContentStore())
const { activeProduct: product } = storeToRefs(
  useProductVariantsStore(),
)

const mediaCounts = subjects.value.map(subject =>
  useMediaCounts(subject),
)

// Summarize media counts
const summarizedCounts = computed(() => {
  return mediaCounts.reduce(
    (acc, media) => {
      const data = unref(media.data)
      if (data) {
        acc.videos += data.videos
        acc.podcasts += data.podcasts
        acc.quizes += data.quizes
      }
      return acc
    },
    { articles: 0, videos: 0, podcasts: 0, quizes: 0 },
  )
})

const mediaTypes = computed<MediaTypes[]>(() => [
  {
    icon: "article",
    count: subjects.value.length,
    text: subjects.value.length === 1 ? "kapittel" : "kapitler",
  },
  {
    icon: "medal",
    count: summarizedCounts.value.quizes,
    text: summarizedCounts.value.quizes === 1 ? "quiz" : "quizzer",
  },
  {
    icon: "video",
    count: summarizedCounts.value.videos,
    text: summarizedCounts.value.videos === 1 ? "video" : "videoer",
  },
  {
    icon: "headphones",
    count: summarizedCounts.value.podcasts,
    text:
      summarizedCounts.value.podcasts === 1 ? "podcast" : "podcaster",
  },
])
</script>

<template>
  <div
    class="p-6 md:p-8 bg-[--theme-lightest] rounded-lg gap-6 md:gap-8 w-full"
  >
    <div
      :class="[
        'gap-4 justify-around w-full grid place-items-center md:flex',
        mediaTypes.filter(type => type.count).length < 4
          ? ' grid-cols-1 '
          : ' grid-cols-2 ',
      ]"
    >
      <div
        v-for="type in mediaTypes"
        :key="type.icon"
        :class="[{ '!hidden': !type.count }]"
        class="flex items-center gap-2"
      >
        <u-icon
          :name="type.icon"
          size="sm"
          class="p-2 bg-light-blue-dark rounded-full text-white"
        />
        <div
          class="font-national2condensed text-paywall-mobile-stats-md md:text-paywall-desktop-stats-md text-[--theme-dark]"
        >
          <span>{{ type.count }}&nbsp;</span>
          <span>{{ type.text }}</span>
        </div>
      </div>
    </div>
  </div>
</template>
