import type { Criterion, Query } from "~/models/Search"
import { formatSearchResults } from "~/mapping/formatSearchResults"
import { buildQuery } from "~/utilities/search-query"
import { BaseItem } from "~/models/Content/BaseItem"
import { mapContents } from "~/mapping/mapToContentTypes"
import * as Sentry from "@sentry/vue"

export interface FetchResultsOptions {
  limit?: number
  offset?: number
}

const MAX_DEPTH = 2

export async function fetchResults<T extends BaseItem>(
  query: Query,
  criterion: Criterion,
  options: FetchResultsOptions,
  depth: number = 0,
): Promise<T[]> {
  const queryString = buildQuery(
    query,
    criterion,
    options.limit ?? 100,
    options.offset ?? 0,
  )

  const response = await api.ibexaApi.get(`/search?${queryString}`)

  const results = formatSearchResults(response.data, queryString)

  const mappedResults = mapContents<T>(results)

  const relationsIds = mappedResults.flatMap(
    item => item.relationsIds,
  )

  if (!relationsIds.length || depth >= MAX_DEPTH) {
    return mappedResults
  }

  try {
    const relations = await fetchResults<T>(
      {},
      { contentIdCriterion: relationsIds },
      options,
      depth + 1,
    )

    return mappedResults.map(it => ({
      ...it,
      relatedItems: relations.filter(relation =>
        it.relationsIds.includes(relation.contentId),
      ),
    }))
  } catch (error) {
    Sentry.captureException(error)
    return mappedResults
  }
}
