import {
  createHtmlFormatter,
  sanitizeMathMl,
} from "~/utilities/dom-parsing"
import { Relation } from "~/types/article"
import { ContentType } from "~/models/Content/ContentType"
import { getContent } from "~/api/getContent"
import {
  MathTaskIbexa,
  MathTaskIbexaSolution,
  MathTaskIbexaSuggestedSolution,
  MathTaskIbexaSuggestedSolutionSection,
} from "~/models/Content/schemas"

export interface FormattedMathTaskSolutions {
  mathTaskId: number
  assignmentText: string
  priority: number
  suggestedSolution: {
    title?: string
    sections?: FormattedSuggestedSolutionSection[]
  }
  solution?: MathTaskIbexaSolution
}

export interface FormattedSuggestedSolutionSection {
  id?: string
  priority: number
  relations: Relation[]
  sectionBody?: string
  sectionImage?: {
    id?: string
    variation?: {
      uri: string
    }
    alternativeText?: string
  }
}

export interface AssignmentSolutions {
  hasSuggestedSolutions: boolean
  assignments: Map<number, FormattedMathTaskSolutions>
}

export async function getMathTaskSolutionFormat(
  locationId: number,
): Promise<AssignmentSolutions | undefined> {
  const mathTasks = await fetchMathTasks(locationId)
  if (!mathTasks || !mathTasks.length) return undefined

  const parser = new DOMParser()
  const assignments = new Map<number, FormattedMathTaskSolutions>()
  let hasSuggestedSolutions = false

  for (const task of mathTasks) {
    const formattedTask = formatMathTask(task, parser)

    if (
      !hasSuggestedSolutions &&
      hasSuggestedSolutionSections(formattedTask)
    ) {
      hasSuggestedSolutions = true
    }

    assignments.set(task.locationId, formattedTask)
  }

  return { hasSuggestedSolutions, assignments }
}

async function fetchMathTasks(
  locationId: number,
): Promise<MathTaskIbexa[]> {
  return await getContent<MathTaskIbexa>(
    {
      contentTypeCriterion: [ContentType.MathTaskIbexa],
      parentLocationIdCriterion: [locationId],
      sortField: "priority",
      sortOrder: "asc",
    },
    { includeChildren: true, maxDepth: 2 },
  )
}

function formatMathTask(
  task: MathTaskIbexa,
  parser: DOMParser,
): FormattedMathTaskSolutions {
  const { solution, suggestedSolutions } = processMathTaskChildren(
    task.children,
    parser,
  )

  return {
    mathTaskId: task.locationId,
    assignmentText: task.assignmentText.html,
    priority: task.priority,
    suggestedSolution: formatSuggestedSolutions(
      suggestedSolutions,
      parser,
    ),
    solution,
  }
}

function processMathTaskChildren(children: any[], parser: DOMParser) {
  let solution: MathTaskIbexaSolution | undefined
  const suggestedSolutions: MathTaskIbexaSuggestedSolution[] = []

  for (const child of children) {
    if (child.contentTypeIdentifier === "solution") {
      solution = formatSolution(child, parser)
    } else if (
      child.contentTypeIdentifier ===
      ContentType.SuggestedSolutionIbexa
    ) {
      suggestedSolutions.push(child)
    }
  }

  return { solution, suggestedSolutions }
}

function formatSolution(
  solutionContent: MathTaskIbexaSolution,
  parser: DOMParser,
): MathTaskIbexaSolution {
  return {
    ...solutionContent,
    solutionText: {
      ...solutionContent.solutionText,
      html: sanitizeMathMl(solutionContent.solutionText.html, parser)
        .body.innerHTML,
    },
  }
}

function formatSuggestedSolutions(
  suggestedSolutions: MathTaskIbexaSuggestedSolution[],
  parser: DOMParser,
) {
  const sections = suggestedSolutions.find(
    s =>
      s.contentTypeIdentifier === ContentType.SuggestedSolutionIbexa,
  )?.children as MathTaskIbexaSuggestedSolutionSection[]

  return {
    title: "Løsningsforslag",
    sections: formatSuggestedSolutionSections(sections, parser),
  }
}

function formatSuggestedSolutionSections(
  sections: MathTaskIbexaSuggestedSolutionSection[] = [],
  parser: DOMParser,
): FormattedSuggestedSolutionSection[] {
  return sections
    .map(section => {
      const relations = (section.relatedItems as Relation[]) ?? []
      const formatHtml = createHtmlFormatter(relations, [], parser)
      return {
        id: section.locationId?.toString(),
        priority: section.priority,
        relations,
        sectionBody: formatHtml(section.sectionBody.html),
        sectionImage: section.sectionImage
          ? {
              id: section.sectionImage.id?.toString(),
              variation: { uri: section.sectionImage.src },
              alternativeText: section.sectionImage.alt,
            }
          : undefined,
      }
    })
    .sort((a, b) => a.priority - b.priority)
}

function hasSuggestedSolutionSections(
  task: FormattedMathTaskSolutions,
): boolean {
  return !!task.suggestedSolution.sections?.length
}
