<script setup lang="ts">
import { ProductsList } from "~/models/Content/ContentProducts"
import UChooseInstitute from "./UChooseInstitute.vue"
import { useEcommerceCatalogue } from "~/composables/useEcommerceCatalogue"
import { useToastStore } from "~/store/toastStore"
import { formProductKeys } from "~/components/Hubspot/productForms"
import { useProductPriceDisplay } from "~/composables/useProductPriceDisplay"

export type ViewType = "list" | "detail"

const {
  product,
  viewType = "list",
  align = "left",
  showStatusDetails = false,
} = defineProps<{
  product: ProductsList
  viewType?: ViewType
  align?: "center" | "left"
  showStatusDetails?: boolean
}>()

const router = useRouter()
const showVariantModal = ref(false)
provide("showVariantModal", showVariantModal)

function goToProductContentButtonHandler() {
  if (product.variants.length === 1) {
    const path = `/produkter/${product.productKey ?? "exphil"}/${
      product.variants[0].variantKey
    }`

    const query = !hasValidLicence.value
      ? { "til-gratis-kapittel": "true" }
      : undefined

    router.replace({ path, query })
  } else {
    showVariantModal.value = true
  }
}

const {
  productPriceFilteredByEAN,
  isLoading: isEcommerceCatalogueLoading,
  error: isEcommerceCatalogueError,
} = useEcommerceCatalogue()

const toastStore = useToastStore()
const ecommerceCatalogueErrorToastId = "ecommerce-catalogue-error"

watch(
  () => isEcommerceCatalogueError,
  newValue => {
    if (newValue) {
      toastStore.addToast({
        id: ecommerceCatalogueErrorToastId,
        message:
          "Vi klarte ikke å hente prisinformasjonen for produktet",
        type: "error",
      })
    }
  },
)

const hasValidLicence = computed(() => {
  return product.variants.some(p => p.userHasLicence)
})

// Find first product that has an EAN _and_ a set price in Crystallize, as all variants should have same price.
const productVariantPrice = computed(() => {
  const prices = product.variants
    .filter(variant => variant.ean)
    .map(it => productPriceFilteredByEAN(it.ean))

  return prices.find(it => typeof it?.actual === "number")
})

const isProductReleased = computed(() => {
  return product.variants.some(p => p.isReleased)
})

const hasInterestForm = computed(() =>
  formProductKeys.find(it => it === product?.productKey),
)

const {
  hasDiscountPrice: productHasDiscountPriceAboveZero,
  combinedPriceText: productGroupStatusDetails,
} = useProductPriceDisplay(
  productVariantPrice,
  toRef(() => product.productGroupStatusDetails),
  showStatusDetails,
)

const productHasFreeVariant = computed(() => {
  if (
    product.variants.some(
      variant => productPriceFilteredByEAN(variant.ean)?.actual === 0,
    )
  ) {
    return true
  }
  return false
})
</script>

<template>
  <div>
    <UChooseInstitute
      :product="product"
      :show-variant-modal="showVariantModal"
    />
    <div
      class="flex flex-wrap xl:flex-nowrap gap-x-4 lg:justify-between"
      :class="{
        'flex-col lg:items-start gap-y-6 lg:gap-y-8 items-center':
          viewType === 'detail',
        'gap-y-4 flex-col lg:flex-row items-start':
          viewType === 'list',
        'flex-col lg:items-center justify-center': align === 'center',
      }"
    >
      <USimpleSkeleton v-if="isEcommerceCatalogueLoading" />
      <div
        v-else-if="
          product?.hasAtLeastOneReleasedProduct &&
          !isEcommerceCatalogueLoading
        "
        class="flex flex-col gap-2 text-3xl font-extrabold text-left grow"
        :class="{
          'gap-y-1 lg:gap-y-2 items-start': viewType === 'list',
          'gap-y-2 lg:gap-y-4 text-marketing-mobile-p1-p1-bold-l lg:text-marketing-desktop-p1-p1-bold-l items-center lg:items-start':
            viewType === 'detail',
          'lg:items-center': align === 'center',
        }"
      >
        <UPrice
          v-if="productVariantPrice"
          :price="productVariantPrice"
          :view-type="viewType"
        />
        <p
          v-if="
            productHasDiscountPriceAboveZero ||
            productGroupStatusDetails
          "
          class="font-normal text-[--theme-darker]"
          :class="{
            'text-lg lg:text-xl font-national2': viewType === 'list',
            'text-2xl lg:text-3xl font-national2condensed text-center lg:text-left':
              viewType === 'detail',
          }"
        >
          {{ productGroupStatusDetails }}
        </p>
      </div>
      <div
        v-else-if="
          viewType === 'list' && !isEcommerceCatalogueLoading
        "
        class="flex grow items-center italic text-[--theme-dark]"
      >
        <p
          class="font-extrabold text-marketing-mobile-p1-p1-bold-m lg:text-marketing-desktop-p1-p1-bold-s font-national2condensed"
        >
          Kommer snart
        </p>
      </div>
      <div
        class="flex flex-wrap gap-4 md:flex-nowrap"
        :class="{
          'lg:self-end': viewType === 'list',
          'justify-center': viewType === 'detail',
        }"
      >
        <UButtonNew
          v-if="viewType === 'list'"
          bold
          size="md"
          :to="`/produkter/${product.urlFormatProductTitle}`"
        >
          Les mer

          <span class="sr-only"
            >om Kunne {{ product.productTitle }}</span
          >
        </UButtonNew>
        <UButtonNew
          v-if="
            isProductReleased &&
            (hasValidLicence ||
              viewType === 'detail' ||
              productHasFreeVariant)
          "
          bold
          :pill="viewType !== 'detail'"
          :theme="viewType !== 'detail' ? 'cta' : undefined"
          size="md"
          :variant="viewType !== 'detail' ? 'filled' : undefined"
          @click="goToProductContentButtonHandler"
        >
          {{ !hasValidLicence ? "Prøv gratis" : "Til produktet" }}

          <span class="sr-only"
            >om Kunne {{ product.productTitle }}</span
          >
        </UButtonNew>

        <UButtonNew
          v-if="
            product?.hasAtLeastOneReleasedProduct &&
            !productHasFreeVariant &&
            !hasValidLicence
          "
          variant="filled"
          theme="cta"
          pill
          size="md"
          data-pendo="forside-kjop-tilgang"
          bold
          :to="`/produkter/${product.urlFormatProductTitle}/betaling`"
        >
          Kjøp
          <span class="hidden sm:inline">tilgang</span>
          <span class="sr-only"
            >til Kunne {{ product.productTitle }}</span
          >
        </UButtonNew>
        <UButtonNew
          v-else-if="!isProductReleased && hasInterestForm"
          :to="`/produkter/${product.urlFormatProductTitle}/registrer-interesse`"
          :data-pendo="`forside-hold-meg-oppdatert-${product.urlFormatProductTitle}`"
          variant="filled"
          theme="cta"
          size="md"
          pill
        >
          Hold meg oppdatert
        </UButtonNew>
      </div>
    </div>
  </div>
</template>
