import { BaseItem } from "~/models/Content/BaseItem"

export interface ContentProductPageMediaTypes extends BaseItem {
  productMediaTypes: {
    text: string
    icon: IconName
  }[]
}

export type MediaType =
  | "product_media_type_text"
  | "product_media_type_video"
  | "product_media_type_tasks"
  | "product_media_type_quiz"
  | "product_media_type_sound"

export type IconName =
  | "align-left"
  | "video"
  | "puzzle"
  | "medal"
  | "headphones"
  | "article"

export const MEDIA_TYPE_ICONS: Record<MediaType, IconName> = {
  product_media_type_text: "align-left",
  product_media_type_video: "video",
  product_media_type_tasks: "puzzle",
  product_media_type_quiz: "medal",
  product_media_type_sound: "headphones",
}
