const prod = import.meta.env.MODE === "production"
const ssr = import.meta.env.SSR

interface ScriptSource {
  src: string
  id?: string
  async?: boolean
  defer?: boolean
}

const sources: ScriptSource[] = [
  {
    src: "https://js-eu1.hs-scripts.com/25807489.js",
    id: "hs-script-loader",
    async: true,
    defer: true,
  },
  {
    src: "https://cdn-cookieyes.com/client_data/4b9a2b0d9cdf9d97e17edfe6/script.js",
  },
]

const setScript = ({ src, id, async, defer }: ScriptSource): void => {
  const script: HTMLScriptElement = document.createElement("script")
  script.type = "text/javascript"

  if (id) script.setAttribute("id", id)
  if (async) script.setAttribute("async", "async")
  if (defer) script.setAttribute("defer", "defer")

  script.src = src

  document.head.appendChild(script)
}

if (prod && !ssr) sources.forEach(i => setScript(i))
