<script setup lang="ts">
import { toDisplayLanguage } from "../../utilities/videoUtils"
import UButtonNew from "~/components/Interactions/UButtonNew.vue"
import { Video } from "~/types/media"

const props = defineProps<{
  data: {
    video: Video
  }
}>()

const video = computed(() => props.data.video)
const videoElement = ref<HTMLVideoElement | null>(null)
const isPlaying = ref(false)
const videoLoaded = ref(false)
const showTime = ref(true)
const showTranscription = ref(false)

const onLoad = () => {
  videoLoaded.value = true
}

const convertToDigits = (x: number) => (x < 10 ? `0${x}` : x)

const getMinutes = () => {
  if (
    props.data.video.pathString === null ||
    props.data.video.pathString === undefined
  )
    return
  const sec = parseInt(props.data.video.metadata?.duration, 10)
  const minutes = Math.floor(sec / 60)
  const seconds = sec - minutes * 60

  return `${convertToDigits(minutes)}:${convertToDigits(seconds)}`
}


const toggleVideoPlay = () => {
  if (
    videoElement.value &&
    (!isPlaying.value || videoElement.value.paused)
  ) {
    if (showTime.value) {
      showTime.value = false
    }
    videoElement.value.play()
    isPlaying.value = true
  } else {
    videoElement.value?.pause()
    isPlaying.value = false
  }
}

const convertToSeconds = (time: string) => {
  return time
    .split(":")
    .reverse()
    .reduce(
      (prev, curr, i) =>
        prev + (curr as unknown as number) * Math.pow(60, i),
      0,
    )
}

const onPlay = (time: string) => {
  const seconds = convertToSeconds(time)

  if (!videoElement.value) return

  videoElement.value.currentTime = seconds
  isPlaying.value = true
  showTime.value = false
  videoElement.value.play()
}
</script>

<template>
  <div
    class="flex flex-col self-center w-full gap-4 mt-10 mb-10 lg:flex-row"
  >
    <div
      class="w-full aspect-w-16 aspect-h-9"
      :class="{
        'lg:w-2/3': showTranscription,
        'lg:w-full': !showTranscription,
      }"
    >
      <div class="relative w-full h-full">
        <UButtonNew
          v-if="!isPlaying && videoLoaded"
          circle
          theme="cta"
          variant="filled"
          class="absolute inset-0 z-50 m-auto"
          aria-label="spill av video"
          aria-controls="video-player"
          @click="toggleVideoPlay"
          ><template #prepend>
            <UIcon name="play-filled" size="lg" class="p-2"
          /></template>
        </UButtonNew>
        <video
          v-if="video.metadata.elementURI"
          id="video-player"
          ref="videoElement"
          preload="auto"
          :captions="video.captions"
          controls
          controlsList="nodownload"
          :poster="
            props.data.video.thumbnail
              ? props.data.video.thumbnail
              : undefined
          "
          crossorigin="anonymous"
          playsinline
          :src="video.metadata.elementURI"
          tabindex="0"
          class="object-contain w-full h-full my-auto bg-black rounded-2xl"
          @loadeddata="onLoad"
          @play="isPlaying = true"
          @pause="isPlaying = false"
        >
          <track
            v-for="caption in video.captions"
            :key="caption.captionFile.uri"
            :src="caption.captionFile.uri"
            :srclang="caption.language"
            :label="toDisplayLanguage(caption.language)"
          />
          <span
            v-if="showTime && !getMinutes()?.includes('NaN')"
            class="absolute hidden p-2 text-2xl font-extrabold rounded-lg national-2 left-4 top-4 bg-darker text-platform-white md:block"
            >{{ getMinutes() }}</span
          >
          <span class="sr-only">minutter lengde</span>
        </video>
      </div>
    </div>
    <div
      v-if="showTranscription"
      id="transcription"
      class="flex justify-start items-start border-2 gap-4 flex-col border-white rounded-lg p-6 max-h-[60vh] border-opacity-40 font-national2 lg:w-1/3 break-all"
    >
      <div class="flex items-start justify-between w-full gap-4">
        <span class="text-2xl font-extrabold">Transkripsjon</span>
        <UButtonNew
          aria-label="lukk transkripsjon"
          aria-controls="transcription"
          variant="quite"
          theme="white"
          @click="showTranscription = false"
        >
          <template #prepend>
            <UIcon name="close" />
          </template>
        </UButtonNew>
      </div>

      <p
        v-if="video.transcription.html"
        class="flex-wrap pr-6 overflow-scroll text-xl leading-10"
        v-html="video.transcription.html"
      />
      <p v-else class="wrap">
        Vi fant dessverre ingen transkripsjon, <br />
        <UContactCustomerService />
      </p>
    </div>
  </div>
  <div
    class="flex flex-wrap items-start self-start justify-between gap-4 md:full lg:flex-row"
  >
    <div
      class="sm:max-w-[70%] lg:max-w-[50%] md:w-[60vw] w-full flex flex-wrap gap-4"
    >
      <UButtonNew
        v-for="timestamp in video.timestamps"
        :key="timestamp.id"
        theme="translucent-light"
        pill
        size="sm"
        variant="filled"
        @click="onPlay(timestamp.time)"
      >
        {{ timestamp.time }} {{ timestamp?.description }}
      </UButtonNew>
    </div>
    <UButtonNew
      theme="light"
      size="sm"
      variant="quite"
      @click="showTranscription = !showTranscription"
    >
      <template #prepend>
        <UIcon name="align-left" />
      </template>
      {{
        showTranscription
          ? "Skjul transkripsjon"
          : "Vis transkripsjon"
      }}
    </UButtonNew>
  </div>
</template>
