import type { BaseItem } from "~/models/Content/BaseItem"
import type { Criterion } from "~/models/Search"
import {
  fetchResults,
  FetchResultsOptions,
} from "~/composables/useSearch"
import * as Sentry from "@sentry/vue"

interface GetContentOptions extends FetchResultsOptions {
  includeChildren?: boolean
  maxDepth?: number
}

export async function getContent<T extends BaseItem>(
  criterion: Criterion,
  { ...options }: GetContentOptions = {},
): Promise<T[]> {
  try {
    // Extend query {} to fetchResult when necessary
    const results = await fetchResults<T>({}, criterion, options)

    if (options.includeChildren) {
      return await attachChildren(results, options.maxDepth)
    }

    return results ?? []
  } catch (error) {
    console.error(
      "Rest api request or mapping teh results failed:",
      error,
    )

    Sentry.captureException(error, {
      extra: {
        criterion,
      },
    })
    return []
  }
}

async function attachChildren<T extends BaseItem>(
  items: T[],
  maxDepth?: number,
): Promise<T[]> {
  if (items.length === 0) return items
  if (maxDepth === 0) return items

  // Get all locationIds in one go
  const locationIds = items.map(item => item.locationId)

  // Fetch all children in a single query
  const allChildren = await getContent<BaseItem>(
    {
      parentLocationIdCriterion: locationIds,
      sortField: "priority",
      sortOrder: "asc",
    },
    {
      includeChildren: true,
      maxDepth: !maxDepth ? 0 : maxDepth - 1,
    },
  )

  // Create a map for quick lookup
  const childrenMap = allChildren.reduce(
    (acc, child) => {
      if (child.parentLocationId) {
        if (!acc[child.parentLocationId]) {
          acc[child.parentLocationId] = []
        }
        acc[child.parentLocationId].push(child)
      }
      return acc
    },
    {} as Record<number, BaseItem[]>,
  )

  // Assign children to their parents
  return items.map(item => ({
    ...item,
    children: childrenMap[item.locationId] ?? [],
  }))
}
