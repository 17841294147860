import { computed, MaybeRef } from "vue"
import type { EcommerceProductPrice } from "~/types/ecommerce/product"

export function useProductPriceDisplay(
  productVariantPrice: MaybeRef<
    EcommerceProductPrice | undefined | null
  >,
  productGroupStatusDetails: MaybeRef<string | undefined>,
  showStatusDetails = true,
) {
  // Private computed properties
  const hasOriginalPrice = computed(
    () => (unref(productVariantPrice)?.original ?? 0) > 0,
  )

  const hasDiscountPrice = computed(
    () => (unref(productVariantPrice)?.discounted ?? 0) > 0,
  )

  const originalPriceText = computed(() => {
    if (!hasOriginalPrice.value) return ""
    return `Fullpris ${unref(productVariantPrice)?.original},-`
  })

  const combinedPriceText = computed(() => {
    if (!showStatusDetails && !hasDiscountPrice.value) {
      return ""
    }

    if (hasDiscountPrice.value) {
      return [
        unref(productGroupStatusDetails),
        originalPriceText.value,
      ]
        .filter(Boolean)
        .join(" ")
    } else {
      return unref(productGroupStatusDetails)
    }
  })

  return {
    combinedPriceText,
    originalPriceText,
    hasDiscountPrice,
  }
}
